import './App.css'
import AboutMe from './components/AboutMe'
import Blog from './components/Blog'
import ResponsiveAppBar from './components/ResponsiveAppBar'

function App() {
  return (
    <ResponsiveAppBar
      sections={[
        { title: 'About Me', content: <AboutMe /> },
        { title: 'Blog', content: <Blog /> }
      ]}
    ></ResponsiveAppBar>
  )
}

export default App
