import { useEffect, useState } from "react"

export default function useOnScreen(ref: React.RefObject<Element>) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        {
            rootMargin: '-80px 0px 0px 0px'
        }
    )

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current)
        }
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    })

    return isIntersecting
}