import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import { alpha, styled, Typography } from '@mui/material'
import { PageSection } from '../interfaces/PageSection'
import { createRef, RefObject, useState } from 'react'
import skyBackground from '../static/images/avatar/sky_image.jpg'
import useOnScreen from '../interfaces/UseOnScreen'

interface ResponsiveAppBarProps {
  sections: PageSection[]
}

const StyledButton = styled(Button)({
  borderRadius: 0,
  '&:hover': {
    backgroundColor: alpha('#e6e6e6', .3),
  },
})

function ResponsiveAppBar(props: ResponsiveAppBarProps) {
  const [title, setTitle] = useState('About Me')
  const sectionRefs: RefObject<HTMLDivElement>[] = Array.from({ length: props.sections.length }, a => createRef<HTMLDivElement>())
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sectionVisibility = sectionRefs.map(ref => useOnScreen(ref))

  function scrollToSection(section: PageSection, index: number): void {
    setTitle(section.title)
    const node = sectionRefs[index].current
    if (node) {
      window.scroll(0, node.offsetTop - 80)
    }

  }

  const handleScroll = (event: Event) => {
    const visibleSectionIndex = sectionVisibility.findIndex(val => val)
    if (props.sections[visibleSectionIndex] && title !== props.sections[visibleSectionIndex].title) {
      setTitle(props.sections[visibleSectionIndex].title)
    }
  }

  window.addEventListener('scroll', handleScroll)

  return (
    <div style={{ backgroundImage: `url(https://images.unsplash.com/photo-1515694346937-94d85e41e6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmFpbnxlbnwwfHwwfHw%3D&w=1000&q=80%27)`, backgroundSize: 'cover', display: 'flex', flexGrow: 1 }}>
      <AppBar position='fixed' sx={{ backgroundImage: `url(${skyBackground})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '80px', minHeight: '80px', display: 'flex', justifyContent: 'center', top: 0 }}>
        <Toolbar sx={{ marginLeft: '10%', marginRight: '10%' }} disableGutters>
          <Typography variant='h4'>MATTHEW DAUGHTERY</Typography>
          <div style={{ display: 'flex', justifyContent: 'right', flexGrow: 1, height: '100%' }}>
            {props?.sections?.map((section, index) => (
              <StyledButton onClick={() => scrollToSection(section, index)} sx={{ height: '100%', backgroundColor: (title === section.title) ? alpha('#e6e6e6', .2) : 'inherit' }}>
                <Typography color={'white'}>{section.title}</Typography>
              </StyledButton>
            ))}
          </div>
        </Toolbar>
      </AppBar>
      <div style={{ width: '80%', margin: '0px auto' }}>
        {props?.sections?.map((section, index) => {
          return <div style={{ marginBottom: '10px' }} ref={sectionRefs[index]}>{section.content}</div>
        })}
      </div>
    </div >
  )
}

export default ResponsiveAppBar