import { Card, Box, CardMedia, Typography } from "@mui/material"

interface LabelledImageProps {
    label: string,
    imgSrc: string,
    backgroundColor?: string
}

function LabelledImage(props: LabelledImageProps) {
    return (
        <Card sx={{ marginRight: 1, flex: 1 }}>
            <Box sx={{ position: 'relative', backgroundColor: props.backgroundColor }}>
                <Box sx={{ padding: 1 }}>
                    <CardMedia
                        sx={{
                            flex: 1,
                            objectFit: 'scale-down',
                        }}
                        component="img"
                        height="150"
                        image={props.imgSrc}

                    />
                </Box>

                <Box
                    sx={{
                        position: 'relative',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.54)',
                        color: 'white',
                        marginTop: '3px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="h5">{props.label}</Typography>
                </Box>
            </Box>
        </Card>
    )
}
export default LabelledImage