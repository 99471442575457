import { alpha, Avatar, Paper, Tooltip, Typography } from "@mui/material"
import storkAndroidApp from '../static/images/avatar/stork_android_app.png'
import weatherAd from '../static/images/avatar/weather_ad.jpg'

function blog() {
    return (
        <>
            <Paper elevation={2} sx={{ borderRadius: 0, backgroundColor: alpha('#e6e6e6', .9), padding: 1 }}>
                <Typography variant='h5' fontFamily='sans-serif' fontWeight='bold' sx={{ display: 'flex', justifyContent: 'center' }}>
                    - Blog -
                </Typography>
                <Paper elevation={2} sx={{ borderRadius: 0, backgroundColor: alpha('#34baeb', .2), padding: 1, position: 'relative', width: '60%', margin: 'auto' }}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant='h6' fontWeight='bold'>
                            Weather, Programming, and Covid - The Birth of an Android App
                        </Typography>
                        <Tooltip title='Last Updated 11/2/2022' placement='right' arrow><text style={{ display: 'flex', justifyContent: 'right', flexGrow: 1, textDecorationLine: 'underline' }}>11/1/2022</text></Tooltip>
                    </div>

                    <div style={{ textAlign: 'justify', width: '95%', margin: 'auto' }}>
                        <Avatar variant={'rounded'} alt="The image" sx={{ float: 'right', height: '30%', width: '30%', marginBottom: '-10px' }} src={storkAndroidApp} />

                        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
                            <b style={{ fontSize: '18px' }}>Part One: The Perfect Storm</b><br /><br />

                            &emsp;The perfect storm to cause someone to branch out had arrived... A pandemic sweeping the globe causing widespread lockdowns, forcing me indoors and limiting activities outside the house.
                            Looking for ideas to put the recently aquired extra time, I noticed that the National Weather Service (NWS) did not have a mobile app for their weather. There was also the nonstop tiresome ads forced upon
                            me by other apps such as Weather.com's, as well as the abundance of bloat not pertaining to weather that affects me, or just about weather whatsoever. Nobody wants to have to click out of an ad to simply
                            close the radar and go back to the main page of the app. So I did what any programmer would do in the situation, started to research the public&nbsp;
                            <Tooltip title='Application programming interface' arrow><text style={{ textDecorationLine: 'underline' }}>API</text></Tooltip> that the&nbsp;
                            <Tooltip title='National Weather Service' arrow><text style={{ textDecorationLine: 'underline' }}>NWS</text></Tooltip> exposes to access their weather data.
                            <br /><br />
                        </p>
                    </div>
                    <div style={{ textAlign: 'justify', width: '95%', margin: 'auto' }}>
                        <b style={{ fontSize: '18px' }}>Part Two: Android's just java, Right?</b><br /><br />

                        <Avatar variant={'rounded'} alt="The image" sx={{ float: 'left', paddingRight: '20px', height: '20%', width: '20%', marginRight: '0px', paddingTop: '20px' }} src={weatherAd} />
                        <Typography variant='subtitle2' sx={{ float: 'left', clear: 'left', width: '20%', paddingRight: '20px', textAlign: 'center' }}>Gotta Love Ads</Typography>

                        <p style={{ fontSize: '17px', textAlign: 'justify' }}>

                            &emsp;A Naive programmer with broad Java knowledge fell face first into the trap that is "Barelling towards a final product, without regard for potential complexity, or iterations needed to fix those issues which go overlooked".
                            But I would never do that, I have worked on many coding projects for fun, and at work I deal with a massive web application with multiple backend services... Of course I did exactly what I thought I wouldn't. Created an android
                            app with fatal programming mistakes at nearly every corner. By the time I had gotten through to having a partially functional app that could take in a location and show the weather forecast, the app was crashing from the
                            mismanaged data handling, and synchronous data fetching. Not to mention the non-existant research done into the Android Programming Language meant that the perks of Android that Java did not have went unnoticed. <br /><br />

                            &emsp;Android, while a language built off Java initially, has grown into a behemoth. Google - which owns Android, has banked on <a href="https://kotlinlang.org/" target="_blank" rel="noopener noreferrer">Kotlin</a> (A pragmatic programming language designed for the&nbsp;
                            <Tooltip title='Java Virtual Machine' arrow><text style={{ textDecorationLine: 'underline' }}>JVM</text></Tooltip>), for its future. To this day, I have been feeling the effects of both not learning Kotlin, and not adopting it over
                            Java... But I will talk more on that later, as the greatest pain I experienced stems from it. <br /><br />

                            &emsp;Oh, what do you know... I seem to have run into an unforseen memory limit when the app is paused and resumed. Silly me didn't look into the best practices for when the app is loaded/resumed, and has reached the limit causing the app to crash immediately.
                            This limit, is 1<Tooltip title='Megabyte' arrow><text style={{ textDecorationLine: 'underline' }}>MB</text></Tooltip>, to which my multiple bitmaps (A way of storing images) easily surpassed.

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                    </div>

                </Paper>

            </Paper>
        </>
    )
}
export default blog